<template>
  <div>
    <vs-button @click.stop="status = true" color="primary" type="gradient" icon-pack="feather" icon-after
      icon="icon-plus">{{$t('Create User')}}
    </vs-button>
    <vs-sidebar default-index="1" click-not-close position-right parent="body" color="primary"
      class="add-new-data-sidebar  data-sidebar items-no-padding" spacer v-model="status">
      <div class="mt-6 flex items-center justify-between px-6">
        <h4> {{$t('Add new User')}}</h4>
        <feather-icon icon="XIcon" class="cursor-pointer" @click.stop="status = false"></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>

      <div class="p-6" style="overflow: auto;
    height: inherit;">
        <template>
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <vs-avatar size="70px" :src="url" />
          </div>
          <div class="modify-img flex justify-between mt-4 pb-3">
            <!-- <vs-button color="primary" class="m-auto">
              {{$t('Change Image')}}
            </vs-button> -->
            <vs-input class="w-full" type="file" @change="onFileChange" accept="image/*" />
            <span v-if="errors.image" class="text-danger text-sm valdation_error_avatar" v-show="errors.image">
              {{errors.image[0]}}
            </span>
          </div>
        </template>

        <div class="vx-row">
          <div class="vx-col  w-full mb-1">
            <vs-input class="w-full" :label-placeholder="$t('First Name')" v-model="UserData.name" />
            <span v-if="errors.name" class="text-danger text-sm valdation_error_avatar" v-show="errors.name">
              {{errors.name[0]}}
            </span>
          </div>
          <div class="vx-col  w-full mb-1">
            <vs-input class="w-full" :label-placeholder="$t('Last Name')" v-model="UserData.last_name" />
            <span v-if="errors.last_name" class="text-danger text-sm valdation_error_avatar" v-show="errors.last_name">
              {{errors.last_name[0]}}
            </span>
          </div>
          <div class="vx-col  w-full mb-1">
            <vs-input class="w-full" :label-placeholder="$t('User Name')" v-model="UserData.user_name" />
            <span v-if="errors.user_name" class="text-danger text-sm valdation_error_avatar" v-show="errors.user_name">
              {{errors.user_name[0]}}
            </span>
          </div>
          <div class="vx-col  w-full mb-1">
            <vs-input type="email" class="w-full" :label-placeholder="$t('Email')" v-model="UserData.email" />
            <span v-if="errors.email" class="text-danger text-sm valdation_error_avatar" v-show="errors.email">
              {{errors.email[0]}}
            </span>
          </div>
          <div class="vx-col  w-full mb-1">
            <vs-input type="phone" class="w-full" :label-placeholder="$t('Phone')" v-model="UserData.mobile" />
            <span v-if="errors.mobile" class="text-danger text-sm valdation_error_avatar" v-show="errors.mobile">
              {{errors.mobile[0]}}
            </span>
          </div>
          <div class="vx-col  w-full mb-1">
            <vs-input class="w-full" :label-placeholder="$t('TC')" v-model="UserData.tc" />
            <span v-if="errors.tc" class="text-danger text-sm valdation_error_avatar" v-show="errors.tc">
              {{errors.tc[0]}}
            </span>
          </div>
          <div class="vx-col  w-full mb-1">
            <!-- <span v-if="UserData.status" class="select-label-placeholder">
              {{$t('Status')}}
            </span> -->
            <my-select class="selectExample mt-5 py-1" :placeholder="$t('Select Status')" style="width: 100%"
              v-model="UserData.status">
              <vs-select-item :key="index" :value="item" :text="$t(item)" v-for="(item,index) in StatusOption" />
            </my-select>
            <span v-if="errors.status" class="text-danger text-sm valdation_error_avatar" v-show="errors.status">
              {{errors.status[0]}}
            </span>
          </div>
          <div class="vx-col  w-full mb-2">
            <!-- <span v-if="UserData.languages" class="select-label-placeholder">
              {{$t('Language')}}
            </span> -->
            <my-select placeholder="Select Language" class="selectExample mt-5 py-1" style="width: 100%"
              v-model="UserData.languages">
              <vs-select-item :key="index" :value="item" :text="$t(item)" v-for="(item,index) in LanguageOption" />
            </my-select>
            <span v-if="errors.languages" class="text-danger text-sm valdation_error_avatar" v-show="errors.languages">
              {{errors.languages[0]}}
            </span>
          </div>
          <!-- <div class="vx-col  w-full mb-2">
            <span v-if="UserData.Role.length>0" class="select-label-placeholder">
              {{$t('Role')}}
            </span>
            <my-select :placeholder="$t('Select Role')" class="selectExample mt-5 py-1 mb-2" style="width: 100%"
              v-model="UserData.Role">
              <vs-select-item :key="index" :value="item.id" :text="$t(item.name)" v-for="(item,index) in roles" />
            </my-select>
            <span v-if="errors.Role" class="text-danger text-sm valdation_error_avatar" v-show="errors.Role">
              {{errors.Role[0]}}
            </span>
          </div> -->
          <vs-row vs-justify="center">
            <vs-col vs-type="flex" vs-align="center" vs-justify="center">
              <div class="mt-12 mb-12" style="width: max-content">
                <vs-button @click="CreateNewUser()" icon-after color="success" type="gradient" class="mb-2">
                  {{$t('Create User')}}</vs-button>
                <vs-button icon-after color="danger" type="gradient" class="mb-2 ml-3" @click.stop="status=false">
                  {{$t('Cancel')}}
                </vs-button>
              </div>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import axios from '../../../axios'
import MySelect from '../../../components/MySelect'

/* eslint-disable */
  export default {
    components:{MySelect},
    name: "NewUserComponent",
    data: () => ({
      UserData: {
        "name": "",
        "last_name": "",
        "user_name": "",
        "email": "",
        "mobile": "",
        "tc": "",
        "status": "",
        "languages": "",
        "Role": [],

      },
      status: false,
      StatusOption: ['Active', 'Blocked', 'Deactivated'],
      GenderOption: ['Male', 'Female', 'Other'],
      RoleOption: [],
      LanguageOption: [ 'English', 'Turkish'],
      errors: [],
      url: null,
      file: null
    }),
    computed: {
      roles() {
        return this.$store.getters["role/roles"]
      }
    },
    created() {
      this.getData()
    },
    methods: {
      onFileChange(e) {
        this.file = e.target.files[0];
        this.url = URL.createObjectURL(this.file);
      },
      getData() {
        this.$store.dispatch('role/getRoles')
      },
      CreateNewUser() {
        this.errors = []
        let config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }

        let formData = new FormData();
        formData.append('image', this.file);
        formData.append('name', this.UserData.name);
        formData.append('last_name', this.UserData.last_name);
        formData.append('user_name', this.UserData.user_name);
        formData.append('email', this.UserData.email);
        formData.append('mobile', this.UserData.mobile);
        formData.append('tc', this.UserData.tc);
        formData.append('status', this.UserData.status);
        formData.append('languages', this.UserData.languages);
        formData.append('role', 1);

        axios.post('/users', formData, config)
          .then(response=>{
            this.UserData = {
              "name": "",
              "last_name": "",
              "user_name": "",
              "email": "",
              "mobile": "",
              "tc": "",
              "status": "",
              "languages": "",
              "Role": [],
            }
            this.status = false
          }).catch((error) => {
              this.errors = error.response.data.errors
          })
      }
    }
  }

</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
  }

</style>
