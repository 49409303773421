<template>
  <div>

    <vs-prompt :title="$t('Filter')"
               @cancel="close"
               @accept="FilterUsers"
               @close="close"
               :acceptText="$t('Accept')"
               :cancelText="$t('Cancel')"
               :active.sync="IsFilterPopupActiveLocal">
      <div class="con-exemple-prompt">
        <vs-col vs-w="12">
          <h6> {{$t('Filter By Status Of The User')}}</h6>
        </vs-col>
        <vs-col vs-w="12">
          <br/>
        </vs-col>
        <vs-col vs-w="12">

          <ul class="centerx">
            <li>
              <vs-radio color="success" v-model="FilterData.UserStatus" vs-value="Success">
                <vs-chip transparent color="success">
                  {{$t('Active')}}
                </vs-chip>
              </vs-radio>
            </li>
            <li>
              <vs-radio color="warning" v-model="FilterData.UserStatus" vs-value="warning">
                <vs-chip transparent color="warning">
                  {{$t('Deactivated')}}
                </vs-chip>
              </vs-radio>
            </li>
            <li>
              <vs-radio color="danger" v-model="FilterData.UserStatus" vs-value="Danger">
                <vs-chip transparent color="danger">
                  {{$t('Blocked')}}
                </vs-chip>
              </vs-radio>
            </li>
          </ul>
        </vs-col>
        <vs-col vs-w="12">
          <br/>
          <br/>
        </vs-col>
        <vs-col vs-w="12">
          <h6> {{$t('Filter By Balance')}}</h6>
        </vs-col>
        <vs-col vs-w="12">
          <br/>
        </vs-col>
        <vs-col vs-w="12">
          <div class="con-select-example">
            <my-select               class="selectExample"
              v-model="BalanceFilterSelect">
              <vs-select-item :key="index" :value="item.value" :text="$t(item.text)"
                              v-for="(item,index) in BalanceFilterOption"/>
            </my-select>
            <div v-if="BalanceFilterSelect==='Less than'">
              <vs-input class="w-full mt-4" :label="$t('All Balance Less Than')" type="text" v-model="FilterData.LessThan"/>
            </div>
            <div v-if="BalanceFilterSelect==='Bigger than'">
              <vs-input class="w-full mt-4" :label="$t('All Balance Bigger Than')" type="text"
                        v-model="FilterData.BiggerThan"/>
            </div>
            <div v-if="BalanceFilterSelect==='Between two values'">
              <vs-input class="w-full mt-4" :label="$t('Big Value')" type="text" v-model="FilterData.BigValue"/>
              <vs-input class="w-full mt-4" :label="$t('Small Value')" type="text" v-model="FilterData.SmallValue"/>
            </div>
            <br/>
          </div>
        </vs-col>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
/* eslint-disable */
import MySelect from '../../../components/MySelect'

export default {
  components:{MySelect},
    name: "FilterComponent",
  props:['IsFilterPopupActive'],
  data () {
    return {
      BalanceFilterSelect: this.$t('Between two values'),
      BalanceFilterOption: [
        {text: this.$t('Between two values'), value: 'Between two values'},
        {text: this.$t('Bigger than'), value: 'Bigger than'},
        {text: this.$t('Less than'), value: 'Less than'},
      ],
      FilterData: {
        "UserStatus": "",
        "BiggerThan": "",
        "LessThan": "",
        "BigValue": "",
        "SmallValue": "",

      },
    }
  },
    computed: {
      IsFilterPopupActiveLocal: {
        get() {
          return this.IsFilterPopupActive
        },
        set(val) {
          if (!val) {
            this.$emit('closePopup')
          }
        }
      },
    },
    methods: {
      close() {
      this.IsFilterPopupActiveLocal = false
      },
      FilterUsers() {

      },
    },
}
</script>

<style scoped>

</style>
