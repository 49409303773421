<template>
  <div>

    <vs-prompt :title="$t('Change User Status')"
               @cancel="close"
               @accept="changeUserStatus"
               @close="close"
               :color="data.status==='Active'?'danger':'success'"
               :acceptText="data.status===$t('Active')?$t('Block'):$t('Activate')"
               :cancelText="$t('Cancel')"
               :active.sync="IsStatusPopupActiveLocal">
      <div> {{$t('Are you sure you want to change the user status')}}</div>
      <br/>
      <div v-if="data.status==='Blocked'" class="con-exemple-prompt">
        <h6>{{$t('he was blocked because')}}</h6>
        <br/>
        <vs-chip color="danger"> {{ data.blocked_reason }}</vs-chip>
        <br/>
      </div>
      <div v-if="data.status==='Active'" class="con-exemple-prompt">
        <vs-input class="w-full mt-4" :label="$t('Reason of blocking')" type="text" v-model="UserData.blocked_reason"/>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
/* eslint-disable */
export default {
    name: "ChangeStatusComponent",
  props:['IsStatusPopupActive','data'],
    data: () => ({
      StatusOption: ['Active', 'Blocked'],
      UserData: {
        "id":"",
        "status": "",
        "blocked_reason": "",
      },
    }),
    computed: {
      IsStatusPopupActiveLocal: {
        get() {
          return this.IsStatusPopupActive
        },
        set(val) {
          if (!val) {
            this.$emit('closePopup')
          }
        }
      },
    },
    methods: {
      close() {
      this.IsStatusPopupActiveLocal = false
      },
      changeUserStatus() {

        if (this.data.status === "Active") {
          this.UserData.status = 'Blocked';
          this.UserData.id = this.data.id;
        } else if (this.data.status === "Deactivated" || this.data.status === "Blocked") {
          this.UserData.status = 'Active';
          this.UserData.id = this.data.id;
        }

        this.$store.dispatch('user/EditUserStatus',{'userdata':this.UserData})
      },
    },
}
</script>

<style scoped>

</style>
