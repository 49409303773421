<template>
  <div>

    <vs-prompt :title="$t('Change User Role')"
               @cancel="close"
               @accept="QuickChangeRole"
               @close="close"
               :acceptText="$t('Accept')"
               :cancelText="$t('Cancel')"
               :active.sync="IsRolePopupActiveLocal">
      <div> {{$t('Choose The Roles Of The User')}}</div>
      <div class="con-exemple-prompt">
        <my-select           :placeholder="$t('Select Role')"
          class="selectExample mt-5"
          style="width: 100%"
          multiple
          v-model="SelectedRoles">
          <vs-select-item :key="index" :value="item.id" :text="item.name"
                          v-for="(item,index) in RoleOption"/>
        </my-select>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
/* eslint-disable */
import MySelect from '../../../components/MySelect'

export default {
    name: "ChangeRoleComponent",
    components:{MySelect},
  props: {
    IsRolePopupActive: {
      type: Boolean,
      required: true
    },},
    data: () => ({
      AllRoles: [],
      SelectedRoles: [],

      RoleOption: [
        {name: 'Role1', id: '1'},
        {name: 'Role2', id: '2'},
        {name: 'Role3', id: '3'},
      ],
    }),
    computed: {
      IsRolePopupActiveLocal: {
        get() {
          return this.IsRolePopupActive
        },
        set(val) {
          if (!val) {
            this.$emit('closePopup')
            // this.$validator.reset()
            // this.initValues()
          }
        }
      },
    },
    methods: {
      close() {
      this.IsRolePopupActiveLocal = false
      },
      QuickChangeRole() {

      }
    },
}
</script>

<style scoped>

</style>
