<template>
  <div>
    <vs-popup :title="$t('Change Password')"
               color="success"
               :cancelText="$t('Cancel')"
               :active.sync="IsChangePasswordPopupActiveLocal">
      <div class="con-exemple-prompt">
        <div>
        <vs-input class="w-full mt-4" :placeholder="$t('Password')" :label="$t('Password')" type="password"
                  v-model="password.password"/>
          <span v-if="errors.password" class="text-danger text-sm valdation_error_avatar"
                v-show="errors.password">{{errors.password[0]}}</span>
        </div>
        <div>
        <vs-input class="w-full mt-4" :placeholder="$t('Password Confirmation')" type="password"
                  :label="$t('Password Confirmation')" v-model="password.password_confirmation"/>
          <span v-if="errors.password" class="text-danger text-sm valdation_error_avatar"
                v-show="errors.password">{{errors.password[1]}}</span>
        </div>
        <br>
        <vs-button v-if="!GeneratedPassword" @click="GeneratePassword" color="primary" type="gradient">
          {{$t('Generated Password')}}
        </vs-button>
        <div v-if="GeneratedPassword">
          <vs-input class="w-full mt-4"
                    disabled="true"
                    :placeholder="$t('Generated Password')"
                    id="PasswordToCopy" :label="$t('Generated Password')" v-model="GeneratedPassword"/>

          <br>
          <vs-button @click="copy" color="warning" type="gradient">{{$t('Copy Password')}}</vs-button>
          <br>
          <br>
          <vs-checkbox v-model="SavePlacePassword"
                       vs-value="email"
                       class="mr-4 mb-2">
            {{$t('I have copied this password in a safe place.')}}
          </vs-checkbox>
        </div>
        <br>
        <br>
        <br>
        <vs-button color="success" type="gradient" @click="changePassword()" class="mr-3 mb-2">{{$t('Create')}}</vs-button>
        <vs-button color="danger" type="gradient" @click="close()" class="mb-2">{{$t('Cancel')}}</vs-button>
      </div>
    </vs-popup>

  </div>
</template>

<script>
/* eslint-disable */
export default {
    name: "ChangePasswordComponent",
  props:['IsChangePasswordPopupActive','data'],
    data: () => ({
      password:{
      password_confirmation: "",
      password: "",
      },
      SavePlacePassword: true,
      GeneratedPassword: '',
      UserID:'',
      errors:[],
    }),
    computed: {
      IsChangePasswordPopupActiveLocal: {
        get() {
          return this.IsChangePasswordPopupActive
        },
        set(val) {
          if (!val) {
            this.$emit('closePopup')
          }
        }
      },
    },
    methods: {
      close() {
        this.IsChangePasswordPopupActiveLocal = false
        this.SavePlacePassword = true;
        this.password.password = '';
        this.password.password_confirmation = '';
        this.GeneratedPassword = '';
      },
      copy() {
        let PasswordToCopy = document.querySelector('#PasswordToCopy')
        PasswordToCopy.removeAttribute("disabled")
        PasswordToCopy.select()
        this.SavePlacePassword = true;
        document.execCommand('copy');
        PasswordToCopy.setAttribute('disabled', 'disabled')
        this.$vs.notify({
          color: 'success',
          title: this.$t('Successful'),
          text: this.$t('Password has been copied successfully'),
          iconPack: 'feather',
          icon: 'icon-check'
        })
      },
      GeneratePassword() {
        this.GeneratedPassword = Math.random().toString(36).slice(-8) + Math.random().toString(36).slice(-8)
        this.password.password = this.GeneratedPassword
        this.password.password_confirmation = this.GeneratedPassword
        this.SavePlacePassword = false
      },
      changePassword() {
        this.UserID = this.data.id;
        if (this.SavePlacePassword === false){
          this.IsChangePasswordPopupActiveLocal = true
          this.$vs.notify({
            color: 'danger',
            title: this.$t('Error'),
            text: this.$t('Please check "I have copied this password in a safe place."'),
            iconPack: 'feather',
            icon: 'icon-check'

          })
        }
        else  {
          this.$store.dispatch('user/ChangePassword', {'id':this.data.id,'password':this.password}).then(response => {
            this.close();
          }).catch((error) => {
            if(error.response.data.errors)
              this.errors = error.response.data.errors
          })
        }
      },
    },
}
</script>

<style scoped>

</style>
