<template>
  <div>
    <change-status-component :IsStatusPopupActive="ChangeStatus" @closePopup="toggleChangeStatusPopup" :data="TempUser"></change-status-component>
    <change-role-component :IsRolePopupActive="ChangeRole" @closePopup="toggleChangeRolePopup" :data="ChangeRoleData"></change-role-component>
    <FilterComponent :IsFilterPopupActive="Filter" @closePopup="toggleFilterPopup"></FilterComponent>
    <ChangePasswordComponent :IsChangePasswordPopupActive="ChangePassword" @closePopup="toggleChangePasswordPopup" :data="TempUser"></ChangePasswordComponent>
    <ViewPermissions v-if="PermissionPopup" :permissionArgs="[RoleId]" @close-popup="closePermissionPopup"></ViewPermissions>
    <vs-row class="pt-6" vs-justify="center">
      <vs-col vs-w="10" vs-type="flex" vs-align="flex-start" vs-justify="flex-start">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">

         <vs-button @click="activePrompt=true" class="mr-2">Export</vs-button>
        </div>
        <vs-prompt :title="$t('Export To Excel')" class="export-options" @cancle="clearFields" @accept="exportToExcel" :accept-text="$t('Export')"  :cancel-text="$t('Cancel')" @close="clearFields" :active.sync="activePrompt">
        <vs-input v-model="fileName" :placeholder="$t('Enter File Name..')" class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex mt-4">
           <span class="mr-2">{{$t('Cell Auto Width')}}</span>
          <vs-switch v-model="cellAutoWidth">{{$t('Cell Auto Width')}}</vs-switch>
        </div>
    </vs-prompt>
        <new-user></new-user>
      </vs-col>

      <vs-col vs-w="2" vs-type="flex" vs-justify="flex-end" vs-align="flex-end">
      </vs-col>
    </vs-row>
    <vs-row class="pt-12" vs-justify="center">
      <vs-col vs-w="12">
        <template>
          <div id="data-list-list-view" class="data-list-container">
            <vs-pagination-table model_type="user" :data="users" :table_name="$t('Users')">
              <template slot-scope="{data}">
                <template>
                  <vs-th sort-key="id">{{$t('ID')}}</vs-th>
                  <vs-th sort-key="name">{{$t('Identity')}}</vs-th>
                  <vs-th sort-key="email">{{$t('Contact')}}</vs-th>
                  <vs-th sort-key="user_name">{{$t('UserName')}}</vs-th>
                  <vs-th sort-key="status">{{$t('status')}}</vs-th>
                  <vs-th>{{$t('Role')}}</vs-th>
                  <vs-th>{{$t('Options')}}</vs-th>
                </template>
                <template>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="tr.id">
                      <vs-chip color="primary">
                        {{ tr.id }}
                      </vs-chip>
                    </vs-td>
                    <vs-td :data="tr.name">
                      <div style="width: max-content">
                          <span style="color: rgba(var(--vs-primary), 1);" class="ml-2"><font-awesome-icon
                            :icon="['fas', 'address-card']"/></span><span class="ml-2">{{ tr.name +' '+ tr.last_name }}</span>
                      </div>
                      <div style="width: max-content">
                      <span style="color: rgba(var(--vs-primary), 1);" class="ml-2">
                      <font-awesome-icon color="primary" :icon="['fas', 'passport']"/>
                      </span>
                        {{ tr.tc }}
                      </div>
                    </vs-td>
                    <vs-td :data="tr.email">
                      <div @click="SendEmail(tr.email)" style="width: max-content">
                        <vx-tooltip color="primary" :text="$t('Click to send email')">
                      <span style="color: rgba(var(--vs-primary), 1);" class="ml-2">
                        <font-awesome-icon :icon="['fas', 'envelope']"/>
                      </span>
                          <span class="ml-2">{{ tr.email }}</span>
                        </vx-tooltip>
                      </div>
                      <div @click="MakeCall(tr.mobile)" style="width: max-content">
                        <vx-tooltip color="primary" :text="$t('Click to call')">
                        <span style="color: rgba(var(--vs-primary), 1);" class="ml-2">
                        <font-awesome-icon :icon="['fas', 'phone']"/>
                        </span>
                          <span class="ml-2">{{ tr.mobile }}</span>
                        </vx-tooltip>
                      </div>
                    </vs-td>
                    <vs-td :data="tr.user_name">
                      <div style="width: max-content">
                          <span style="color: rgba(var(--vs-primary), 1);" class="ml-2"><font-awesome-icon
                            :icon="['fas', 'user']"/></span>
                        <span class="ml-2">{{ tr.user_name }}</span>
                      </div>
                    </vs-td>
                    <vs-td :data="tr.status">
                      <div style="width: max-content">
                        <vs-chip transparent
                                 :color="tr.status==='Active'?'success':tr.status==='Blocked'?'danger':'warning'">
                          <vs-avatar
                            :color="tr.status==='Active'?'rgb(var(--vs-success))':tr.status==='Blocked'?'rgb(var(--vs-danger))':'rgb(var(--vs-warning))'"
                            :icon="tr.status==='Active'?'check_circle_outline':tr.status==='Blocked'?'block':'unsubscribe'"
                          />
                          <vx-tooltip :color="tr.status==='Active'?'success':tr.status==='Blocked'?'danger':'warning'"
                                      :text="tr.status==='Active'?$t('This User is active'):tr.blocked_reason"
                                      position="top">
                            {{ tr.status }}
                          </vx-tooltip>
                        </vs-chip>
                      </div>
                    </vs-td>

                    <vs-td :data="tr.rolecount">
                      <div v-if="tr.role.length>0" style="width: max-content">
                        <vx-tooltip color="primary" :text="$t('Click to see the Permissions')" position="top">
                          <span style="color: rgba(var(--vs-primary), 1);" class="ml-2">
                          <font-awesome-icon
                            color="primary"
                            :icon="['fas', 'user-shield']"/>
                          </span>
                            <a style="color: rgba(var(--vs-primary), 1);" class="ml-2"
                               :data="value" :key="indextr" v-for="(value, indextr) in tr.role"
                               @click="OpenPermissionPopUp(value.id)"
                            >{{value.name}}
                            </a>
                          {{ tr.rolecount }}
                        </vx-tooltip>
                      </div>

                    </vs-td>
                    <vs-td :data="tr.id">
                      <div style="width: max-content">
                        <router-link class="ml-1 mr-1" :to="{name:'EditUser',params: { userId: tr.id }}">
                          <font-awesome-icon
                            color="rgb(var(--vs-success))"
                            :icon="['fas', 'edit']"
                          />
                        </router-link>
                        <a class="ml-1 mr-1" @click="removeUserAlert(tr.id)">
                          <font-awesome-icon
                            color="rgb(var(--vs-danger))"
                            :icon="['fas', 'trash-alt']"
                          />
                        </a>
                        <a class="ml-1 mr-1" @click="ChangePassword=true ,TempUser = tr ">
                          <font-awesome-icon
                            color="rgb(var(--vs-warning))"
                            :icon="['fas', 'key']"
                          />
                        </a>
                        <!-- <span style="color: rgba(var(--vs-primary), 1);" class="ml-1 mr-1">
                          <vs-dropdown>
                             <font-awesome-icon
                               class="mr-2"
                               color="rgb(var(--vs-primary))"
                               :icon="['fas', 'ellipsis-v']"
                             />
                            <vs-dropdown-menu>
                              <vs-dropdown-item @click="ChangeStatus = true , TempUser = tr"> {{$t('Change Status')}} </vs-dropdown-item>
                              <vs-dropdown-item @click="ChangeRole=true"> {{$t('Change Role')}} </vs-dropdown-item>
                               <vs-dropdown-item divider> {{$t('Log In')}} </vs-dropdown-item>
                            </vs-dropdown-menu>
                          </vs-dropdown>
                        </span> -->
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </template>
            </vs-pagination-table>
          </div>
        </template>
      </vs-col>
    </vs-row>
    <!-- <ExplanationsComponent></ExplanationsComponent> -->
  </div>
</template>
<script>
/* eslint-disable */
import NewUser from "@/views/pages/user/NewUserComponent"
import ChangeRoleComponent from "@/views/pages/user/ChangeRoleComponent";
import ExplanationsComponent from "@/views/pages/user/userExplantionComponent";
import ChangeStatusComponent from "@/views/pages/user/ChangeStatusComponent";
import FilterComponent from "@/views/pages/user/FilterComponent";
import ChangePasswordComponent from "@/views/pages/user/ChangePasswordComponent";
import ViewPermissions from "@/views/pages/role/ViewPermissions";
import vSelect from 'vue-select'
import axios from '../../../axios'

export default {
  components: {
    ViewPermissions,
    ChangePasswordComponent,
    FilterComponent, ChangeStatusComponent, ExplanationsComponent, ChangeRoleComponent, NewUser,
    vSelect
  },
  data: () => ({
    RoleId:'',
    ChangeRole: false,
    ChangeStatus: false,
    ChangeRoleData: {},
    ChangeStatusData: {},
    ChangePassword: false,
    Filter: false,
    UserID: '',
    TempUser: [],
    log: [],
    PermissionPopup:false,
      fileName: '',
      formats:['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      headerTitle: ['Id', 'Name Surname', 'Email','Phone', 'Username', 'Status'],
      headerVal: ['id','full_name', 'email','mobile', 'user_name', 'status'],
      activePrompt: false
  }),
  watch: {},
  computed: {
    users() {
      return this.$store.getters["user/users"]
    },
  },
  methods: {
    exportToExcel () {
      axios.get('/users?nopaginate=true').then(response=>{
        import('../../../components/excel/ExportExcel').then(excel => {
        const list = response.data.users
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat || 'xlsx'
        })
        this.clearFields()
      })
      })

    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
         return v[j]
      }))
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    MakeCall(tel) {
      window.location.href = `tel://${tel}`;
    },
    SendEmail(email) {
      window.location.href = `mailto://${email}`;
    },
    removeUserAlert($val) {
      this.UserID = $val
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: this.$t('Are you sure you want to delete this User'),
        accept: this.acceptAlert,
        acceptText: this.$t('Delete'),
        cancelText: this.$t('Cancel')
      })
    },
    acceptAlert() {
      this.$store.dispatch('user/DeleteUser', this.UserID)
      this.getData()
    },
    toggleChangeRolePopup(val = false) {
      this.ChangeRole = val
    },
    toggleChangeStatusPopup(val = false) {
      this.ChangeStatus = val
    },
    toggleChangePasswordPopup(val = false) {
      this.ChangePassword = val
    },
    toggleFilterPopup(val = false) {
      this.Filter = val
    },
    closePermissionPopup(){
      this.PermissionPopup = false;
    },
    OpenPermissionPopUp($val){

      this.RoleId = $val
      this.PermissionPopup = true;
    }


  },

}
</script>
<style>
.select-label-placeholder {
  position: absolute;
}

</style>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}


.vs-sidebar {
  z-index: 400000;
}
</style>





